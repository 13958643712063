<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-04-03 11:38:37
 * @LastEditTime: 2023-11-17 18:27:01
 * @Descripttion: 【作业考试-课堂练习】批阅
-->
<style lang="scss" scoped>
.practice-read {
  @include innerPage;
  @include pageHead(-42px);
  .page-inner {
    width: 100%;
    height: calc(100% - 194px);
    margin-top: 32px;
    @include flexBox(space-between, flex-start);
    .students {
      width: 360px;
      height: 100%;
      border-radius: 10px;
      background: #f0f3fa;
      box-sizing: border-box;
      padding: 6px 10px 10px;
      overflow: hidden;

      .statistics-read {
        width: 100%;
        height: 122px;
        border-bottom: 1px solid #e2e4ea;
        position: relative;
        @include flexBox;

        p {
          width: 50%;
          height: 100%;
          line-height: 36px;
          font-size: 16px;
          color: #2a2a2a;
          flex-direction: column;
          @include flexBox(center);

          b {
            font-size: 22px;
            color: #6340c8;
          }

          span {
            margin-top: -2px;
          }

          &:first-child b {
            color: #28ca96;
          }
        }

        &:after {
          content: "";
          width: 1px;
          height: 14px;
          background: #7d7d7d;
          position: absolute;
          bottom: 37px;
          left: 50%;
          transform-origin: 0 0;
          transform: translateX(-50%) scaleX(0.5);
        }
      }

      ul {
        width: 100%;
        height: calc(100% - 162px);
        margin-top: 30px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 0 30px;

        .student {
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          padding-top: 6px;
          padding-right: 24px;
          margin-bottom: 30px;
          cursor: pointer;
          @include flexBox;

          .el-avatar {
            border: 2px solid #c8c3e3;
            border-radius: 50%;
          }

          .read-info {
            width: calc(100% - 72px);
            height: 100%;
            margin-left: 10px;
            flex-direction: column;
            @include flexBox(space-around, flex-start);

            .student-info {
              width: 100%;
              height: 36px;
              margin-top: -4px;
              @include flexBox;

              span:not(.name) {
                flex-shrink: 0;
              }

              .name {
                margin-right: auto;
                font-size: 16px;
                color: #2b2b2b;
                flex-grow: 1;
              }

              .pf_bold {
                font-size: 20px;
                color: #ee4f65;
              }
            }

            .read-result {
              line-height: 20px;
              color: #6b6b6b;
            }
          }

          .will-read {
            font-size: 14px;
            color: #656565;
            @include flexBox;

            .iconfont {
              font-size: 24px;
              color: #c8c3e3;
              margin-right: 6px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.readed {
            padding-right: 0;
            align-items: flex-start;
          }

          &.reading {
            .will-read {
              color: #ee4f65;

              .iconfont {
                color: #ee4f65;
              }
            }

            .el-avatar {
              border-color: #ee4f65;
            }
          }

          &:not(.readed):not(.reading):hover {
            transform: translateX(20px);

            .will-read {
              color: #ee4f65;

              .iconfont {
                color: #ee4f65;
              }
            }
          }
        }

        .no-data--empty {
          top: 30%;

          img {
            width: 160px;
          }
        }
      }
    }
    .read-box {
      width: calc(100% - 394px);
      height: 100%;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      .read-exercise {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px 10px;
        flex-direction: column;
        @include flexBox;
        .question-wrapper {
          width: 100%;
          flex-grow: 1;
          box-sizing: border-box;
          padding: 0 36px 0 46px;
          overflow: hidden;
          overflow-y: auto;
          .answer-judgment{
            margin: 20px 0 0;
          }
        }
        > .el-button {
          margin: 14px auto 0;
          flex-shrink: 0;
          display: flex;
        }
      }
    }
  }
}
.question {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 0;
  border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
  @include flexBox;
  align-items: baseline;

  &-picture {
    width: 100%;

    .canvas-box {
      width: 100%;
      min-height: 240px;
      margin: 0 auto;
      position: relative;
    }
  }

  &--num,
  &--type {
    flex-shrink: 0;
    line-height: 24px;
    flex-shrink: 0;
  }

  &--num {
    color: #1f1f1f;
    font-size: 16px;
  }

  &--type {
    min-width: 56px;
    height: 24px;
    background: #6340c8;
    border-radius: 12px 0px 12px 12px;
    text-align: center;
    color: #fff;
    margin: 0 14px 0 4px;
    box-sizing: border-box;
    padding: 0 4px;
  }

  &--data {
    width: calc(100% - 100px);

    h5 {
      line-height: 24px;
      color: #1f1f1f;
    }

    .imgs-group {
      margin-top: 10px;
      @include flexBox;

      .el-image {
        // max-width: 100%;
        width: 130px;
        height: 78px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }

    .options {
      margin-top: 14px;

      li {
        line-height: 30px;
        color: #666;

        .option--text {
          display: inline-flex;
          align-items: flex-start;
        }

        .imgs-group {
          margin-top: -4px;
          margin-bottom: 2px;
          box-sizing: border-box;
          padding-left: 52px;
        }

        &:last-child {
          .option--text {
            margin-bottom: 0;
          }

          .imgs-group {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  ::v-deep .el-radio {
    &__input {
      &:not(.is-disabled) {
        .el-radio__inner {
          &:hover {
            border-color: #2ac293;
          }
        }
      }

      &.is-checked {
        .el-radio__inner {
          border-color: #2ac293;
          background: #2ac293;
        }

        & + .el-radio__label {
          color: #2ac293;
        }
      }

      &__original:disabled {
        cursor: pointer;
      }
    }

    &.wrong {
      .el-radio__input{
        &:not(.is-disabled).el-radio__inner {
          &:hover {
            border-color: #f66478;
          }
        }

        &.is-checked {
          .el-radio__inner {
            border-color: #f66478;
            background: #f66478;
          }

          & + .el-radio__label {
            color: #f66478;
          }
        }
      }
    }
  }
}

.compose {
  &-wrapper {
    width: calc(100% + 70px);
    margin-left: -70px;
    margin-top: 18px;

    h5 {
      height: 38px;
      @include flexBox;

      span.bold {
        display: inline-block;
        flex-grow: 1;
        margin-right: 20px;
      }
    }

    .subtopic {
      margin-top: 20px;

      &-item {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 30px;
        background: rgba($color: #eef0f8, $alpha: 0.4);
        border-radius: 10px;
        color: #666;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &--title {
        font-size: 16px;
        line-height: 24px;
      }

      &--options {
        box-sizing: border-box;
        padding: 0 40px;
        margin-top: 16px;

        .option {
          line-height: 30px;

          &--text {
            margin-bottom: 16px;
            display: inline-flex;
            align-items: flex-start;
          }

          .imgs-group {
            margin-top: -4px;
            margin-bottom: 2px;
            box-sizing: border-box;
            padding-left: 52px;
          }

          &:last-child {
            .option--text {
              margin-bottom: 0;
            }

            .imgs-group {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.answer {
  line-height: 24px;
  font-size: 14px;
  margin: 20px 0;
}

.stuanswer_image {
  width: 100px;
  height: 150px;
  margin: 20px 0;
  border: 1px solid #dddddd;
  .el-image {
    width: 100%;
    height: 100%;
  }
}
.right-answer {
  width: 100%;
  min-height: 52px;
  background: #f0f3fa;
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 30px;
  font-size: 16px;
  color: #393737;
  @include flexBox(space-between, center);

  span {
    @include flexBox;
    align-items: baseline;

    &:last-child {
      color: #2ac293;
    }
  }

  .iconfont {
    font-size: 20px;
    color: #ee4f65;
  }
}

.radio {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f1f1f1;
  border: solid 1px #dcdcdc;
  flex-shrink: 0;
  margin: 5px 14px 5px 0;
}

.wrong {
  .radio {
    border-color: #f66478;
    background: #f66478;
  }
}

.correct {
  .radio {
    border-color: #2ac293;
    background: #2ac293;
  }
}
</style>

<template>
  <section class="practice-read">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
      <div class="head-wrapper">
        <breadcrumb />
        <el-button type="custom_primary" @click="$router.go(-1)" size="medium"
          >返回</el-button
        >
      </div>
    </div>
    <div class="page-inner">
      <div class="students">
        <div class="statistics-read">
          <p>
            <b class="pf_bold">{{ studentsInfo.YesReadOver }}</b>
            <span>已批阅（份）</span>
          </p>
          <p>
            <b class="pf_bold">{{ studentsInfo.noReadOver }}</b>
            <span>未批阅（份）</span>
          </p>
        </div>
        <ul :class="{ 'no-data': $isEmpty(studentsInfo.data) }">
          <li
            class="student"
            :class="{
              readed: stu.status == 10,
              reading: currentReadIndex == stu_index,
            }"
            v-for="(stu, stu_index) in studentsInfo.data"
            :key="stu.stuser_id"
            @click="changeStudent(stu_index)"
          >
            <el-avatar
              fit="cover"
              :size="62"
              :src="formatfile(stu.stuser_image)"
              class="avatar"
            >
              <img src="@assets/images/empty_avatar.png" />
            </el-avatar>
            <div class="read-info">
              <!-- 已批阅 -->
              <template v-if="stu.status == 10">
                <p class="student-info">
                  <span
                    class="name line-text--1st"
                    :title="
                      (stu.stuser_name &&
                        stu.stuser_name.length > 3 &&
                        stu.stuser_name) ||
                      ''
                    "
                  >
                    {{ stu.stuser_name }}
                  </span>
                  <span class="will-read">
                    <i class="iconfont">&#xe61e;</i>已批阅
                  </span>
                </p>
              </template>
              <!-- 未批阅 -->
              <template v-if="stu.status != 10">
                <p class="student-info">
                  <span
                    class="name line-text--1st"
                    :title="
                      (stu.stuser_name &&
                        stu.stuser_name.length > 3 &&
                        stu.stuser_name) ||
                      ''
                    "
                  >
                    {{ stu.stuser_name }}
                  </span>
                  <span class="will-read">
                    <i class="iconfont">&#xe61e;</i>待批阅
                  </span>
                </p>
              </template>
            </div>
          </li>
          <li class="no-data--empty" v-if="$isEmpty(studentsInfo.data)">
            <img src="@assets/images/no-data3.png" alt="" />
            <p>暂无学生数据哦~</p>
          </li>
        </ul>
      </div>
      <div
        class="read-box"
        :class="{ 'no-data': $isEmpty(currentReadPractice) }"
      >
        <div class="read-exercise" v-show="!$isEmpty(currentReadPractice)">
          <ul class="question-wrapper" ref="questionWrapper">
            <li
              class="question"
              :class="{ compose: item.syque_typ_id == 14 }"
              v-for="(item, que_index) in currentReadPractice"
              :key="item.teles_pra_id"
            >
              <span class="question--num pf_bold">
                {{
                  (Number(que_index) + 1 > 9 && Number(que_index) + 1) ||
                  `0${Number(que_index) + 1}`
                }}.
              </span>
              <span class="question--type">{{ item.syque_typ_name }}</span>
              <div class="question--data">
                <h5 class="bold richinline" v-html="item.teles_pra_title"></h5>
                <div class="imgs-group" v-if="item.teles_pra_title_image">
                  <el-image
                    fit="cover"
                    :src="formatfile(item.teles_pra_title_image)"
                    :preview-src-list="[formatfile(item.teles_pra_title_image)]"
                  />
                </div>
                <!-- 组合题 -->
                <template v-if="item.syque_typ_id == 14">
                  <div class="compose-wrapper">
                    <ol class="subtopic">
                      <li
                        class="subtopic-item"
                        v-for="(s_item, s_index) in item.question_item"
                        :key="s_item.teles_pra_id"
                      >
                        <p class="subtopic--title">
                          （{{ s_index + 1 }}）【{{ s_item.syque_typ_name }}】<span class="richinline" v-html="s_item.teles_pra_title"></span>
                        </p>
                        <div
                          class="imgs-group"
                          v-if="s_item.tepralog_stu_answer_image"
                        >
                          <el-image style="width: 100px;height: 150px;border: 1px solid #dddddd;"
                            fit="cover"
                            :src="formatfile(s_item.tepralog_stu_answer_image)"
                            :preview-src-list="[
                              formatfile(s_item.tepralog_stu_answer_image),
                            ]"
                          />
                        </div>
                        <template v-if="isChoice(s_item.syque_typ_id)">
                          <dl class="subtopic--options">
                            <dt
                              class="option"
                              v-for="o in s_item.question_item"
                              :key="o.teles_pra_ite_id"
                              :class="{
                                wrong:
                                  s_item.tepralog_status != 1 &&
                                  s_item.tepralog_stu_answer &&
                                  s_item.tepralog_stu_answer.indexOf(
                                    o.teles_pra_ite_code
                                  ) != -1,
                                correct:
                                  s_item.tepralog_status == 1 &&
                                  s_item.tepralog_stu_answer &&
                                  s_item.tepralog_stu_answer.indexOf(
                                    o.teles_pra_ite_code
                                  ) != -1,
                              }"
                            >
                              <span class="option--text">
                                <i class="radio"></i>
                                {{ o.teles_pra_ite_code }}.
                                <span class="richinline" v-html="o.teles_pra_ite_title"></span>
                              </span>
                              <div
                                class="imgs-group"
                                v-if="o.teles_pra_ite_image"
                              >
                                <el-image
                                  fit="cover"
                                  :src="formatfile(o.teles_pra_ite_image)"
                                  :preview-src-list="[
                                    formatfile(o.teles_pra_ite_image),
                                  ]"
                                />
                              </div>
                            </dt>
                          </dl>
                          <div
                            class="right-answer"
                            v-if="s_item.tepralog_status != 1"
                          >
                            <span>
                              回答错误：
                              <i class="iconfont">&#xe63f;</i>
                            </span>
                            <span
                              >正确答案：<span class="richinline" v-if="(Array.isArray(s_item.sure_answer) && s_item.sure_answer.join(','))" v-html="s_item.sure_answer.join(',')"></span></span
                            >
                          </div>
                        </template>
                        <template v-if="!isChoice(s_item.syque_typ_id)">
                          <p
                            class="answer"
                            v-if="!$isEmpty(s_item.tepralog_stu_answer)"
                          >
                            作答内容：<span class="richinline" v-if="(Array.isArray(s_item.tepralog_stu_answer) && s_item.tepralog_stu_answer.join(','))" v-html="s_item.tepralog_stu_answer"></span>
                          </p>
                          <!-- 手写板图片 -->
                          <div
                            class="stuanswer_image"
                            v-if="s_item.sthom_que_stuanswer_image"
                          >
                            <el-image
                              class="el-image"
                              :src="formatfile(s_item.tepralog_stu_answer_img)"
                              fit="cover"
                              :preview-src-list="[
                                formatfile(s_item.tepralog_stu_answer_img),
                              ]"
                            />
                          </div>
                          <div class="answer-judgment">
                            <el-radio-group
                              v-model="s_item.tepralog_status"
                              :disabled="currentReadStu.status == 10"
                              @input="
                                determineRight(
                                  $event,
                                  s_item.teles_pra_id,
                                  s_item.tepralog_id
                                )
                              "
                            >
                              <el-radio class="correct" :label="10">正确</el-radio>
                              <el-radio class="wrong" :label="20">错误</el-radio>
                            </el-radio-group>
                          </div>
                        </template>
                      </li>
                    </ol>
                  </div>
                </template>
                <!-- 其他题型 -->
                <template v-if="item.syque_typ_id != 14">
                  <!-- 选择题选项 -->
                  <template v-if="isChoice(item.syque_typ_id)">
                    <ul class="options">
                      <li
                        :class="{
                          wrong:
                            item.tepralog_status != 1 &&
                            item.tepralog_stu_answer &&
                            item.tepralog_stu_answer.indexOf(
                              o.teles_pra_ite_code
                            ) != -1,
                          correct:
                            item.tepralog_status == 1 &&
                            item.tepralog_stu_answer &&
                            item.tepralog_stu_answer.indexOf(
                              o.teles_pra_ite_code
                            ) != -1,
                        }"
                        v-for="o in item.question_item"
                        :key="o.teles_pra_ite_id"
                      >
                        <span class="option--text">
                          <i class="radio"></i>
                          {{ o.teles_pra_ite_code }}.
                          <span class="richinline" v-html="o.teles_pra_ite_title"></span>
                        </span>
                        <div class="imgs-group" v-if="o.teles_pra_ite_image">
                          <el-image
                            fit="cover"
                            :src="formatfile(o.teles_pra_ite_image)"
                            :preview-src-list="[
                              formatfile(o.teles_pra_ite_image),
                            ]"
                          />
                        </div>
                      </li>
                    </ul>
                    <div class="right-answer" v-if="item.tepralog_status != 1">
                      <span>
                        回答错误：
                        <i class="iconfont">&#xe63f;</i>
                      </span>
                      <span
                        >正确答案：<span class="richinline" v-if="(Array.isArray(item.sure_answer) &&item.sure_answer.join(','))" v-html="item.sure_answer.join(',')"></span> 
                      </span>
                    </div>
                  </template>
                  <!-- 非选择题 -->
                  <template v-if="!isChoice(item.syque_typ_id)">
                    <p
                      class="answer"
                      v-if="!$isEmpty(item.tepralog_stu_answer)"
                    >
                      作答内容：<span class="richinline" v-if="(Array.isArray(item.tepralog_stu_answer) &&item.tepralog_stu_answer.join(','))" v-html="item.tepralog_stu_answer"></span>
                    </p>
                    <!-- 手写板图片 -->
                    <div
                      class="stuanswer_image"
                      v-if="item.tepralog_stu_answer_img"
                    >
                      <el-image
                        class="el-image"
                        :src="formatfile(item.tepralog_stu_answer_img)"
                        fit="cover"
                        :preview-src-list="[
                          formatfile(item.tepralog_stu_answer_img),
                        ]"
                      />
                    </div>
                    <div class="answer-judgment">
                      <el-radio-group
                        v-model="item.tepralog_status"
                        :disabled="currentReadStu.status == 10"
                        @input="
                          determineRight(
                            $event,
                            item.teles_pra_id,
                            item.tepralog_id
                          )
                        "
                      >
                        <el-radio class="correct" :label="1">正确</el-radio>
                        <el-radio class="wrong" :label="2">错误</el-radio>
                      </el-radio-group>
                    </div>
                  </template>
                </template>
              </div>
            </li>
          </ul>
          <el-button
            v-if="currentReadStu.status != 10"
            type="custom_primary"
            size="medium"
            :disablde="btnload"
            v-loading="btnload"
            @click="finishRead"
            >确定</el-button
          >
        </div>
        <div class="no-data--empty" v-show="$isEmpty(currentReadPractice)">
          <img src="@assets/images/no-data3.png" alt="" />
          <p>暂无数据哦~</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  $practiceStudents,
  $studentAnswerPractice,
  $determinePractice,
  $finishReadPractice,
} from "@api/homeworkExam";
import { formatFile, iptFloat } from "@utils";
export default {
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    iptInit() {
      return function (val) {
        return iptFloat(val);
      };
    },
    iptInitBlur() {
      return function (val) {
        if (!val) return 0;
        if (val && val.split(".")[1] == "") return val.split(".")[0];
        return val;
      };
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    /** 判断当前学生作业是否已完成批阅 */
    isReaded() {
      return this.currentReadPractice.sthom_wor_status == 30;
    },
  },
  data() {
    return {
      grade: null,
      class: null,
      tecla_id: null, // 授课课堂id
      studentsInfo: {}, // 学生信息（批阅）
      currentReadIndex: 0, // 当前批阅学生下标
      currentReadStu: {}, // 当前批阅学生信息
      currentReadPractice: {}, // 当前批阅练习信息
      btnload: false,
      scrollTop: 0,
    };
  },
  created() {
    let { id, tecla } = this.$route.params;
    id = id.split(",");
    this.grade = id[0];
    this.class = id[1];
    this.tecla_id = tecla;
    // this.tecla_id = 611;
    this.getStudentsInfo();
  },
  mounted() {
    let timer = setInterval(() => {
      if (this.$refs.questionWrapper) {
        clearInterval(timer);
        this.$refs.questionWrapper.addEventListener("scroll", this.boxScroll);
      }
    }, 500);
  },
  methods: {
    /** 获取批阅学生信息 */
    async getStudentsInfo() {
      let { data: res } = await $practiceStudents(this.tecla_id);
      this.studentsInfo = { ...res };
      this.currentReadStu = res.data[this.currentReadIndex];
      this.getReadInfo();
    },
    /** 获取当前批阅答题信息 */
    async getReadInfo() {
      let id = this.currentReadStu.stuser_id;
      let { data } = await $studentAnswerPractice(this.tecla_id, id);
      this.currentReadPractice = (this.$isEmpty(data) && {}) || { ...data };
      this.$nextTick(() => {
        this.$refs.questionWrapper.scrollTop = 0;
      });
      this.$forceUpdate();
    },
    /** 切换学生作业信息 */
    changeStudent(index) {
      this.currentReadIndex = index;
      this.currentReadStu = this.studentsInfo.data[index];
      this.getReadInfo();
    },
    /** 页面滚动 */
    boxScroll() {
      this.scrollTop = this.$refs.questionWrapper.scrollTop;
    },
    /**
     * 提交非选择题批阅
     * @param {number} val 判定类型
     * @param {number} teles_pra_id 题目id
     * @param {number} tepralog_id 手写板答题id
     */
    async determineRight(val, teles_pra_id, tepralog_id) {
      await $determinePractice(teles_pra_id, tepralog_id, val);
    },
    /** 完成批阅 */
    async finishRead() {
      this.btnload = true;
      let res = await $finishReadPractice(
        this.tecla_id,
        this.currentReadStu.stuser_id
      );
      this.btnload = false;
      if (res) {
        this.getStudentsInfo();
      }
    },
  },
};
</script>
